<template lang="pug">
  .home-page
    section#steps1.hero
      home-banner

    section#steps2.conversion
      conversion-block
      benefits-block

    section#steps3.features
      features-block
      design-block
      app-block

    section#steps4.price
      h3.title.price__title
        | КОМПЛЕКТИ ПОСТАВКИ DRIVELINK
      price-block
    section.additional-services
      h3.title.additional-services__title
        | ДОДАТКОВІ ПОСЛУГИ
      div.additional-services__container
        price-slider

    section#steps5.contacts
      .contacts__container
        .contacts__img
          img(src="~@/assets/images/contacts-img.jpg", alt="solider")
        .contacts__content
          h3.title.contacts__title
            | Залишити заявку
          form(action="/", method="POST").contacts__form
            label(for="full_name").contacts__label
              span Ім'я
              input(
                type="text"
                name="full_name"
                required
                placeholder="Ваше ім'я"
                v-model="full_name"
                @input="validateName"
              ).contacts__input
              div(v-if="nameTouched && !nameIsValid").error Введіть коректне ім'я
            label(for="phone_number").contacts__label
              span Номер телефону
              input(
                type="tel"
                name="phone_number"
                required
                placeholder="Номер телефону для зв'язку"
                v-model="phone_number"
                @focus="handlePhoneFocus"
                @input="validatePhone"
                @blur="handlePhoneBlur"
              ).contacts__input
              .error(v-if="phoneTouched && !phoneIsValid")
                span(v-if="phone_number.length < 13") Занадто мало символів
                span(v-else) Занадто багато символів
            label(for="email").contacts__label
              span Email
              input(
                type="email"
                name="email"
                required
                placeholder="Адреса електронної пошти для зв'язку"
                v-model="email"
                @input="validateEmail"
              ).contacts__input
              .error(v-if="emailTouched && !emailIsValid") Введіть коректну електронну адресу
            label(for="additional_info").contacts__label
              span Напишіть, що Вас цікавить
              input(
                name="additional_info"
                required
                placeholder="Ваше повідомлення"
                v-model="additional_info"
                @input="validateMessage"
              ).contacts__input
              .error(v-if="messageTouched && !messageIsValid") Введіть повідомлення
            button(
              type="submit"
              @click.prevent="submit"
            ).btn.contacts__button Відправити
    a(
      href="#"
      :class="{ active: isScrolled }"
      @click.prevent="scrollToTop"
    ).top
      svg(width='16', height='16', viewBox='0 0 16 16', fill='none', xmlns='http://www.w3.org/2000/svg')
        path(d='M8 15V1M8 1L1 8M8 1L15 8', stroke='#101828', stroke-width='2', stroke-linecap='round', stroke-linejoin='round')
    .popup(
      v-show="isShowPopup"
      @closepopup="isShowPopup = false"
      @click="removePopup"
    )
      .popup__wrapper
        .popup__close(@click="removePopup")
          img(src="~@/assets/images/popupclose.svg" alt="close")
        p.popup__title Заявку надіслано!
        p.popup__text Дякуємо за Ваше звернення! Наші спеціалісти звʼяжуться з Вами якомога швидше.
</template>

<script>
export default {
  name: 'HomePage',
  components: {
    HomeBanner: () => import('@/components/HomeBanner'),
    ConversionBlock: () => import('@/components/ConversionBlock'),
    BenefitsBlock: () => import('@/components/BenefitsBlock'),
    FeaturesBlock: () => import('@/components/FeaturesBlock'),
    DesignBlock: () => import('@/components/DesignBlock'),
    AppBlock: () => import('@/components/AppBlock'),
    PriceBlock: () => import('@/components/PriceBlock'),
    PriceSlider: () => import('@/components/PriceSlider')
  },
  data () {
    return {
      full_name: '',
      phone_number: '',
      email: '',
      additional_info: '',
      nameTouched: false,
      phoneTouched: false,
      emailTouched: false,
      messageTouched: false,
      phoneIsValid: false,
      nameIsValid: false,
      emailIsValid: false,
      messageIsValid: false,
      isScrolled: false,
      isShowPopup: false
    }
  },
  mounted () {
    window.addEventListener('scroll', this.handleScroll)
  },
  destroyed () {
    window.removeEventListener('scroll', this.handleScroll)
  },
  methods: {
    validateName () {
      const regex = /^[a-zA-Zа-яА-ЯіІїЇєЄґҐ'` ]+$/
      this.nameIsValid = regex.test(this.full_name)
    },
    validatePhone () {
      const regex = /^\+380\d{9}$/
      this.phoneIsValid = regex.test(this.phone_number)
    },
    handlePhoneFocus () {
      if (this.phone_number === '') {
        this.phone_number = '+380'
        this.validatePhone()
      }
    },
    handlePhoneBlur () {
      if (this.phone_number === '+380') {
        this.phone_number = ''
      }
      this.validatePhone()
    },
    validateEmail () {
      const regex = /^[^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*@[^<>()[\]\\.,;:\s@"]+\.([a-zA-Z]{2,})$/
      this.emailIsValid = regex.test(this.email)
    },
    validateMessage () {
      const regex = /^[a-zA-Zа-яА-ЯіІїЇєЄґҐ'` 0-9?!().,"]+$/
      this.messageIsValid = regex.test(this.additional_info)
    },
    validateForm () {
      if (this.full_name.length === 0 && this.phone_number.length === 0 && this.email.length === 0 && this.additional_info.length === 0) {
        this.nameIsValid = false
        this.phoneIsValid = false
        this.emailIsValid = false
        this.messageIsValid = false
      }
    },
    submit () {
      this.nameTouched = true
      this.phoneTouched = true
      this.emailTouched = true
      this.messageTouched = true
      this.validateForm()
      if (this.nameIsValid && this.phoneIsValid && this.emailIsValid && this.messageIsValid) {
        const requestOptions = {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify({
            full_name: this.full_name,
            email: this.email,
            phone_number: this.phone_number,
            additional_info: this.additional_info
          })
        }
        fetch(`${ process.env.VUE_APP_API }/api/v1/contact_me_form/`, requestOptions)
          .then(response => {
            if (response?.status === 200) {
              this.isShowPopup = true
              this.waitPopup()
              this.full_name = ''
              this.phone_number = ''
              this.email = ''
              this.additional_info = ''
              this.phoneTouched = false
              this.nameTouched = false
              this.emailTouched = false
              this.messageTouched = false
              this.phoneIsValid = false
              this.nameIsValid = false
              this.emailIsValid = false
              this.messageIsValid = false
            }
          })
      }
    },
    removePopup () {
      this.isShowPopup = false
    },
    waitPopup () {
      setTimeout(() => {
        this.isShowPopup = false
      }, 4000)
    },
    handleScroll () {
      this.isScrolled = window.scrollY > 100
    },
    scrollToTop () {
      window.scrollTo({
        top: 0,
        behavior: 'smooth'
      })
    }
  }
}
</script>
